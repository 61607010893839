import React from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

//Images
import { SurfacePreparationServiceImages } from '../imageURLs';

//Data
import keyProcesses from '../../data/surface-preparation/key-processes.json';

import CenterContentNarrow from '../components/CenterContentNarrow';
import SEO from '../components/SEO';
import { withQuoteWizard } from '../components/withQuoteWizard';
import CtaBlock from '../components/CtaBlock';
import  CTAButton from '../components/CTAButton/CTAButton';
import InnerImage from '../components/InnerImage/innerImage';
import FullWidthCarouselSlider from '../components/Carousel/FullWidthCarouselSlider';
import ProcessText from '../components/singleproject/processText'
import Description from '../components/Description';
import TitleSmaller from '../components/TitleSmaller';
import ServiceIntroSection from '../components/ServiceIntroSection';


const heroSliderItems = [
  {
    imagePath: SurfacePreparationServiceImages.hero1,
  },
  {
    imagePath: SurfacePreparationServiceImages.hero2,
  },
  {
    imagePath: SurfacePreparationServiceImages.hero3,
  },

];

const styles = theme =>
  createStyles({
    heroSliderWrap: {
        position: 'relative',
        top: '0',
        left: '0',
    },

    img: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },

    twoColumnsWrap: {
      alignItems: 'flex-start',
      flexWrap: 'wrap',
      [theme.breakpoints.up(960)]: {
        flexDirection: 'row',
        paddingTop: 40,
        paddingBottom: 130,
      },
    },

    leftColumnWrap: {
      padding: '40px',
      [theme.breakpoints.up(960)]: {
        flex: '0 0 52%',
        maxWidth: '52%',
        paddingRight: 110,
      },
      [theme.breakpoints.up(1220)]: {
        padding: '40px 120px 40px 0'
      },
    },

    rightColumnWrap: {
      [theme.breakpoints.up(960)]: {
        flex: '0 0 48%',
        maxWidth: '48%',
      },
    },

    rightColumnText: {
      backgroundColor: theme.palette.grey.main,
      padding: '40px',
      alignItems: 'flex-start',
      order:1,
      [theme.breakpoints.up(960)]: {
        order: 0
      },
    },

    rightColumnImage: {
        maxHeight: '506px',
        overflow: 'hidden',
        [theme.breakpoints.up(767)]: {
          maxHeight: '560px'
        },

    },

    listItemsWrap: {
      paddingLeft: 20,
      marginTop: 0,
    },

  });

const SurfacePreparationServicePage = ({classes,toggleQuoteComp,children}) => (

<React.Fragment>

  <SEO
    title="Surface Preparation - Twin View Turf - Producer of Australia’s Best Lawns"
    keywords={[
      `Turf care guides`,
      `care guides`,
      `Turf`,
      `Lawns`,
      `Best Lawns`,
      `Australia’s Best Lawns`,
      `lawn producer`,
    ]}
/>

    {/* HERO SECTION */}
      <Grid
        container
        className={classes.heroSliderWrap}
      >
        <FullWidthCarouselSlider largeImage items={heroSliderItems}  />
      </Grid>

    {/* SERVICES - INTRO SECTION */}
      <ServiceIntroSection
        title="Surface Preparation"
        subTitle="The key to a high performing field."
        text="We have decades of practical experience in shaping and contouring playing surfaces for a diverse range of sports fields."
      ></ServiceIntroSection>

        {/* TWO COLUMNS SECTION START */}
        <CenterContentNarrow>
        <Grid
          container
          direction="column"
          className={classes.twoColumnsWrap}
        >
          {/* left column */}
          <Grid
            container
            direction="column"
            className={classes.leftColumnWrap}
          >
          {keyProcesses.map(process => (
            <ProcessText
              key={process.title}
              processTitle={process.title}
              processDesc={process.desc}
            />
            ))}

          </Grid>
          {/* right column */}
          <Grid
            container
            direction="column"
            className={classes.rightColumnWrap}
          >
            {/* right column text */}
            <Grid
              container
              className={classes.rightColumnText}
            >

            <TitleSmaller
              title="UTS Precision"
            ></TitleSmaller>

            <Description 
              descText="We leverage the latest technologies to ensure the utmost in accuracy during the field construction. Our machinery is equipped with the latest in universal total station technology allowing millimetre accuracy in finished levels to engineered designs."
            ></Description>
            
            </Grid>
            
            {/* right column image */}
            <Grid
              container
              className={classes.rightColumnImage}
            >
              <InnerImage
                image={{ className: classes.img, src: SurfacePreparationServiceImages.thumb }}
              ></InnerImage>
            </Grid>

          </Grid>
        </Grid>
      </CenterContentNarrow>

    {/* CTA SECTION START */}
    <CtaBlock title="Planning a field construction?">
      <CTAButton
        inverted
        ctaHref="/contact"
        ctaText="Get a quote"
      />
    </CtaBlock>

  {children}
</React.Fragment>

)

export default withQuoteWizard(withStyles(styles, { withTheme: true })(SurfacePreparationServicePage));